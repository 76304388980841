<template>
  <v-container>
    <core-toolbar-name :page-name="this.$tc('message.pages.title.create_function_language', 1)"></core-toolbar-name>

    <v-item-group>
      <v-row>
        <v-col v-for="(item, i) in items" :key="i" cols="12" md="6" xl="4">
          <v-dialog transition="dialog-top-transition" max-width="450">
            <template v-slot:activator="{ on, attrs }">
              <v-item v-slot="{ active }" >
                <v-card :color="active ? 'primary' : item.color" dark height="280 " class="d-flex align-stretch"
                  scrollable v-bind="attrs" v-on="on" @click="setLanguage(item)">
                  <div class="d-flex flex-no-wrap justify-space-between align-stretch">
                    <div class="white">
                      <v-avatar class="ma-3" tile min-width="96" min-height="226">
                        <v-img :src="require('@/assets/lang-icons/' + item.logo)" contain min-height="226">
                          <v-icon v-if="active" color="green" large>
                            mdi-check
                          </v-icon>
                        </v-img>
                      </v-avatar>
                    </div>
                    <div>
                      <v-card-title class="headline" v-text="item.name"></v-card-title>
                      <v-card-subtitle v-text="item.description"> </v-card-subtitle>
                    </div>
                  </div>
                </v-card>
              </v-item>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title><span class="text-h6 font-weight-light">{{ $t('message.labels.function-name') }}</span></v-card-title>
                <v-text-field class="pa-5" :label="$t('message.labels.name')" v-model="functionName" :rules="rules"></v-text-field>    
                <v-card-actions class="justify-end">
                  <v-btn text @click="createFunction(dialog)" :disabled="creationDisabled">{{ $t('message.btns.create') }}</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <v-snackbar light top elevation="1" v-model="snackbar">
            {{ text }}
            <template v-slot:action="{ attrs }">
              <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                {{ $t('message.btns.no') }}
              </v-btn>
              <v-btn color="pink" text v-bind="attrs" @click="redirectToFunctionCreation">
                {{ $t('message.btns.yes') }}
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-item-group>

  </v-container>
</template>

<script>
import functionChooseLangMixin from "@/mixins/views/pages/FunctionsCreateLanguage.js";
import CoreToolbarName from "@/components/core/ToolbarName.vue";

export default {
  name: "FunctionCreateLanguage",
  data() {
    return {
      language:"",
      functionName:"",
      snackbar: false,
      text: this.$t('message.functioncreation.snackbar.functionexists'),
      rules: [
        (value) => !!value || this.$t("message.error.function.name-required"),
        (value) =>
          value.length < 63 || this.$t("message.error.function.name_length"),
        (value) => {
          const pattern = /^([a-z0-9-]+)$/;
          return (
            pattern.test(value) ||
            this.$t("message.error.function.name-invalid")
          );
        },
      ],
      creationDisabled: true
    }
  },
  watch: {
    functionName(newFunctionName) {
      this.creationDisabled = !newFunctionName || newFunctionName.length > 63 || !/^([a-z0-9-]+)$/.test(newFunctionName); 
    }
  },
  components: {
    CoreToolbarName,
  },
  mixins: [functionChooseLangMixin],
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    setLanguage(item) {
      this.language = item.name;
    },
    createFunction(dialog) {
      dialog.value = false;
      if (this.functionNameExists(this.functionName)) {
        this.snackbar = true;
      } else {
        this.redirectToFunctionCreation();
      }  
    },
    redirectToFunctionCreation() {
      this.redirectToCreateNewForm(this.language, this.functionName);
    },
    functionNameExists(name) {
      return this.$route.params.items.some(item => item.name == name); 
    }
  },
};
</script>
