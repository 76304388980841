import runtimes from '@/assets/runtimes.json'
import connectionMixin from "@/mixins/connectionParams.js";
import axios from 'axios';
export default {
  mixins: [connectionMixin],
  data: () => ({
    items: runtimes,
  }),

  methods: {
    redirectToCreateNewForm(lang, functionName) {
      this.createInitFiles(lang, functionName);
    },

    createInitFiles(lang, functionName) {
      axios
        .post(
          this.apihost + "/api/v1/files/" + lang + "?functionName=" + functionName,
          {},
          this.opts
        )
        .then((response) => {
          this.$router.push({
            name: "Create new function form",
            params: {
              readOnly: false,
              language: lang.toUpperCase(),
              initFiles: response.data.files,
              id: functionName,
              version: response.data.version
            },
          });
        })
        .catch((err) => {
          console.error("failed to initialize files", err);
        });
    },
  },
};