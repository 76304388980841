import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            apihost: '',
            headers: ''
        }
    },
    computed: {
        serverlessConf: { get() { return this.getServerless() } },
        tokenKey: { get() { return this.getKeycloakToken() } },
    },
    created() {
        this.apihost = this.serverlessConf.apihost
        this.opts = {
            headers: {
                'Authorization': 'Bearer ' + this.tokenKey,
            }
        }
    },
    methods: {
        ...mapGetters('loginModule', [
            'getKeycloakToken'
        ]),
        ...mapGetters('serverlessModule', [
            'getServerless'
        ]),
    }
}