<template>
  <div class="name-toolbar mb-5">
    <v-toolbar flat dense>
      <v-toolbar-title class="grey--text">
        {{ pageName }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <v-row v-if="!isFirstPage">
      <v-col cols="12">
        <v-btn text color="secondary" @click="goBack">
          <v-icon left>mdi-chevron-left</v-icon>
          {{ $t("message.btns.go-back") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CoreToolbarName",
  props: ["pageName"],
  data: () => ({
    // isNotFirstPage: false,
  }),
  computed: {
    isFirstPage() {
      return this.$router.history.current.path == "/";
    },
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
    // currentPath() {
    //     var current_path = this.$router.history.current.path;
    //     if(current_path!=="/"){
    //         this.isNotFirstPage = true
    //     }
    // }
  },
  mounted() {
    // this.currentPath();
  },
};
</script>
